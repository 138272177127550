<template>
   <div class="obi-page" :class="[`obi-page--${type}`]">
      <div class="obi-page-header" v-if="title || subtitle">
         <div class="obi-page-icon">
            <slot name="icon"></slot>
         </div>
         <div class="obi-page-content">
            <div class="obi-page-title" v-if="title" v-html="title"></div>
            <div class="obi-page-subtitle" v-if="subtitle" v-html="subtitle"></div>
         </div>
         <div class="obi-page-actions">
            <slot name="actions"></slot>
         </div>
      </div>

      <div class="obi-page-body">
         <slot></slot>
      </div>

      <div class="obi-page-footer" v-if="$slots.footer">
         <slot name="footer"></slot>
      </div>
   </div>
</template>

<script>
export default {
   name: 'ObiPage',
   props: {
      type: {
         type: String,
         default: 'page',
      },
      title: {
         type: String,
      },
      subtitle: {
         type: String,
      },
   },
};
</script>

<style lang="scss">
.obi-page {
   height: 100%;
   position: relative;

   .obi-page-actions {
      margin-left: auto;
   }

   .obi-page-title {
      color: #2f71f2;
      font-size: 18px;
   }

   .obi-page-subtitle {
      color: #777d96;
      font-size: 12px;
      margin-top: 6px;
   }

   .obi-page-content {
      display: flex;
      line-height: 1;
      flex-direction: column;
   }

   .obi-page-footer {
      padding: 20px;
      border-top: 1px solid #eaeaea;
      background-color: #f9f9f9;
   }
   .obi-page-body {
      height: 100%;
   }
   .obi-page-header {
      display: flex;
   }

   &.obi-page--dialog {
      .obi-page-body {
         padding: 60px 100px;
      }

      .obi-page-footer {
         padding: 20px 100px;
      }
   }

   > .v-card__title {
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #eeeeee;
   }
}
</style>
